'use strict';

// Setting up route
angular.module('admin-area').config([
    '$stateProvider',
    '$urlRouterProvider',
    function ($stateProvider, $urlRouterProvider) {
        $urlRouterProvider
            .when('/admin_area', '/admin_area/dashboard')
            .when('/admin_area/status', '/admin_area/status/devices')
            .when('/admin_area/cms', '/admin_area/cms/utils');

        var belongsTo = 'admin-area';

        // Player state routing
        $stateProvider
            .state('admin-area', {
                url: '/admin_area',
                //                abstract: true,
                templateUrl: 'modules/admin-area/views/admin-area.client.view.html',
                controller: 'AdminAreaController',
                data: {
                    security: {
                        secured: true,
                        roles: ['1', '3'], // admin, pro_curator
                    },
                },
            })

            .state('admin-area.dashboard', {
                url: '/dashboard',
                templateUrl: 'modules/admin-area/views/admin-area.dashboard.client.view.html',
                data: {
                    security: {
                        secured: true,
                        roles: ['1'], // admin
                    },
                },
            })

            .state('admin-area.reports', {
                url: '/reports',
                templateUrl: 'modules/admin-area/views/admin-area.reports.client.view.html',
                controller: 'AdminAreaReportsController',
                data: {
                    security: {
                        secured: true,
                        roles: ['1'], // admin
                    },
                },
            })

            .state('admin-area.statuses', {
                url: '/statuses',
                templateUrl: 'modules/admin-area/views/admin-area.statuses.client.view.html',
                controller: 'AdminAreaStatusesController',
                controllerAs: 'vm',
                data: {
                    security: {
                        secured: true,
                        roles: ['1'], // admin
                    },
                },
            })

            .state('admin-area.statuses.artworks', {
                url: '/artworks',
                templateUrl: 'modules/admin-area/views/admin-area.statuses.artworks.client.view.html',
                controller: 'AdminAreaStatusesArtworksController',
                controllerAs: 'vm',
            })

            .state('admin-area.statuses.activities', {
                url: '/activities',
                templateUrl: 'modules/admin-area/views/admin-area.statuses.activities.client.view.html',
                controller: 'AdminAreaStatusesActivitiesController',
                controllerAs: 'vm',
            })

            .state('admin-area.statuses.transfers', {
                url: '/transfers',
                templateUrl: 'modules/admin-area/views/admin-area.statuses.transfers.client.view.html',
                controller: 'AdminAreaStatusesTransfersController',
                controllerAs: 'vm',
            })

            .state('admin-area.statuses.displays', {
                url: '/displays',
                templateUrl: 'modules/admin-area/views/admin-area.statuses.displays.client.view.html',
                controller: 'AdminAreaStatusesDisplaysController',
                resolve: {
                    displaysCounts: [
                        'AdminAreaDataService',
                        function (AdminAreaDataService) {
                            return AdminAreaDataService.getGlobalDisplaysCounts();
                        },
                    ],
                },
                controllerAs: 'vm',
            })

            .state('admin-area.statuses.totals', {
                url: '/totals',
                templateUrl: 'modules/admin-area/views/admin-area.statuses.totals.client.view.html',
                controller: 'AdminAreaStatusesTotalsController',
                controllerAs: 'vm',
            })

            .state('admin-area.statuses.users', {
                url: '/users',
                templateUrl: 'modules/admin-area/views/admin-area.statuses.users.client.view.html',
                controller: 'AdminAreaStatusesUsersController',
                controllerAs: 'vm',
            })

            .state('admin-area.services', {
                url: '/services',
                templateUrl: 'modules/admin-area/views/admin-area.services.client.view.html',
                controller: 'AdminAreaServicesController',
            })
            .state('admin-area.services.push', {
                url: '/push',
                templateUrl: 'modules/admin-area/views/admin-area.services.push.client.view.html',
                controller: 'AdminAreaServicesPushController',
                data: {
                    security: {
                        secured: true,
                        roles: ['1', '3'], // admin or pro_curator
                    },
                },
            })

            .state('admin-area.services.re-run', {
                url: '/re-run',
                templateUrl: 'modules/admin-area/views/admin-area.services.re-run.client.view.html',
                controller: 'AdminAreaServicesReRunController',
                data: {
                    security: {
                        secured: true,
                        roles: ['1'], // admin
                    },
                },
            })

            .state('admin-area.services.batch', {
                url: '/batch',
                templateUrl: 'modules/admin-area/views/admin-area.services.batch-import.client.view.html',
                controller: 'AdminAreaServicesBatchImportController',
                controllerAs: 'vm',
                data: {
                    security: {
                        secured: true,
                        roles: ['1'], // admin
                    },
                },
            })

            .state('admin-area.services.api', {
                url: '/api',
                templateUrl: 'modules/admin-area/views/admin-area.services.api.client.view.html',
                controller: 'AdminAreaServicesApiController',
                controllerAs: 'vm',
                data: {
                    security: {
                        secured: true,
                        roles: ['1'], // admin
                    },
                },
            })

            // DEVICE MANAGEMENT
            // Users state routing
            .state('admin-area.devices', {
                url: '/devices',
                templateUrl: 'modules/devices/views/devices.client.view.html',
                controller: 'DevicesController',
                data: {
                    devicesBelongsTo: belongsTo,
                    security: {
                        secured: true,
                        roles: ['1', '3'], // admin, pro-curators
                    },
                },
                resolve: {
                    devicesAndGroups: [
                        'DevicesDataService',
                        function (DevicesDataService) {
                            return DevicesDataService.getDevicesAndGroups();
                        },
                    ],
                },
            })

            // Single Device
            .state('admin-area.devices.device', {
                url: '/device/:id',
                templateUrl: 'modules/devices/views/devices.selected.client.view.html',
                controller: 'DevicesSelectedController',
                data: {
                    deviceOrGroup: 'device',
                },
            })

            .state('admin-area.devices.device.content', {
                url: '/content',
                templateUrl: 'modules/devices/views/devices.selected.content.client.view.html',
                controller: 'DevicesSelectedContentController',
            })

            .state('admin-area.devices.device.settings', {
                url: '/settings',
                templateUrl: 'modules/devices/views/devices.selected.settings.client.view.html',
                controller: 'DevicesSelectedSettingsController',
            })

            .state('admin-area.devices.device.download-management', {
                url: '/download-management',
                templateUrl: 'modules/devices/views/devices.selected.download-management.client.view.html',
                controller: 'DevicesSelectedContentController',
            })

            // Group
            .state('admin-area.devices.group', {
                url: '/group/:id',
                templateUrl: 'modules/devices/views/devices.selected.client.view.html',
                controller: 'DevicesSelectedController',
                data: {
                    deviceOrGroup: 'group',
                },
            })

            .state('admin-area.devices.group.content', {
                url: '/content',
                templateUrl: 'modules/devices/views/devices.selected.content.client.view.html',
                controller: 'DevicesSelectedContentController',
            })

            .state('admin-area.devices.group.settings', {
                url: '/settings',
                templateUrl: 'modules/devices/views/devices.selected.settings.client.view.html',
                controller: 'DevicesSelectedSettingsController',
            })

            // Display Array
            .state('admin-area.devices.group.display-array', {
                url: '/display-array',
                templateUrl: 'modules/devices/views/devices.selected.display-array.client.view.html',
                controller: 'DevicesSelectedDisplayArrayController',
            })

            // Default device settings
            .state('admin-area.devices.default-settings', {
                url: '/default_settings',
                abstract: true,
                templateUrl: 'modules/devices/views/devices.default-settings.client.view.html',
                controller: 'DevicesDefaultSettingsController',
            })

            .state('admin-area.devices.default-settings.basic', {
                url: '/basic',
                templateUrl: 'modules/devices/views/devices.selected.settings.client.view.html',
            })

            .state('admin-area.devices.default-settings.advanced', {
                url: '/advanced',
                templateUrl: 'modules/devices/views/devices.default-settings.advanced.client.view.html',
            })

            // Add Device - from devices
            .state('admin-area.devices.add-device', {
                url: '/add-device',
                templateUrl: 'modules/devices/views/devices.add-device.client.view.html',
                controller: 'DevicesAddDeviceController',
            })

            // Add Group
            .state('admin-area.devices.add-group', {
                url: '/add-group?fromSelected',
                templateUrl: 'modules/devices/views/devices.add-group.client.view.html',
                controller: 'DevicesAddGroupController',
            })

            // Multiple Devices Are Selected
            .state('admin-area.devices.multiple-devices', {
                url: '/multiple-devices',
                templateUrl: 'modules/devices/views/devices.multiple-devices.client.view.html',
                //                controller: 'DevicesMultipleDevicesController'
            })

            // Groups and Devices Are Selected
            .state('admin-area.devices.multiple-devices-and-groups', {
                url: '/multiple-devices-and-groups',
                templateUrl: 'modules/devices/views/devices.multiple-devices-and-groups.client.view.html',
                //                controller: 'DevicesMultipleDevicesController'
            })

            .state('admin-area.cms', {
                url: '/cms/utils',
                templateUrl: 'modules/admin-area/views/admin-area.cms.utils.client.view.html',
                controller: 'AdminAreaCmsUtilsController',
                data: {
                    security: {
                        secured: true,
                        roles: ['1', '3'], // admin, pro_curator
                    },
                },
            })

            //User & Plans
            .state('admin-area.user-plans', {
                url: '/user-plans',
                templateUrl: 'modules/admin-area/views/admin-area.user-plans.client.view.html',
                controller: 'AdminAreaUserPlansController',
                controllerAs: 'userPlansCtrl',
                data: {
                    security: {
                        secured: true,
                        roles: ['1'], // admin
                    },
                },
                resolve: {
                    plans: [
                        'UserPlansDataService',
                        function (UserPlansDataService) {
                            return UserPlansDataService.getAllPlans();
                        },
                    ],
                },
            })
            // Specific user-plan
            .state('admin-area.user-plans.plan', {
                url: '/plan/:planId',
                templateUrl: 'modules/admin-area/views/admin-area.user-plans.selected.client.view.html',
                controller: 'AdminAreaUserPlansSelectedController',
                controllerAs: 'userPlanCtrl',
                resolve: {
                    currentUserPlan: [
                        'UserPlansDataService',
                        '$stateParams',
                        function (UserPlansDataService, $stateParams) {
                            return UserPlansDataService.getUserPlanData($stateParams.planId);
                        },
                    ],
                    planDataAndPath: [
                        'UserPlansDataService',
                        '$stateParams',
                        function (UserPlansDataService, $stateParams) {
                            return UserPlansDataService.getPlanDataAndPath($stateParams.planId);
                        },
                    ],
                },
            })
            //Map user to plan
            .state('admin-area.user-plans.mapping', {
                url: '/mapping',
                templateUrl: 'modules/admin-area/views/admin-area.user-plans.mapping.client.view.html',
                controller: 'AdminAreaUserPlansMappingController',
                controllerAs: 'userPlanMappingCtrl',
            })

            //Events
            .state('admin-area.events', {
                url: '/events',
                templateUrl: 'modules/admin-area/views/admin-area.events.client.view.html',
                controller: 'AdminAreaEventsController',
                controllerAs: 'eventsCtrl',
                data: {
                    security: {
                        secured: true,
                        roles: ['1'], // admin
                    },
                },
                resolve: {
                    events: [
                        'EventsDataService',
                        function (EventsDataService) {
                            return EventsDataService.getAllEvents();
                        },
                    ],
                },
            })
            // Specific event
            .state('admin-area.events.event', {
                url: '/event/:eventId',
                templateUrl: 'modules/admin-area/views/admin-area.events.selected.client.view.html',
                controller: 'AdminAreaEventsSelectedController',
                controllerAs: 'eventCtrl',
                // abstract: true,
                resolve: {
                    currentEvent: [
                        'EventsDataService',
                        '$stateParams',
                        function (EventsDataService, $stateParams) {
                            return EventsDataService.getEventHostData(
                                $stateParams.eventId,
                                null,
                                false /* showOnlySubmitted) */
                            );
                        },
                    ],
                },
            })
            .state('admin-area.events.event.settings', {
                url: '/settings',
                templateUrl: 'modules/admin-area/views/admin-area.events.selected.settings.client.view.html',
                controller: 'AdminAreaEventsSelectedSettingsController',
                controllerAs: 'eventSettingsCtrl',
            })
            .state('admin-area.events.event.users', {
                url: '/users',
                templateUrl: 'modules/admin-area/views/admin-area.events.selected.users.client.view.html',
                controller: 'AdminAreaEventsSelectedUsersController',
                controllerAs: 'eventUsersCtrl',
            })
            .state('admin-area.events.event.content', {
                url: '/content',
                templateUrl: 'modules/admin-area/views/admin-area.events.selected.content.client.view.html',
                controller: 'AdminAreaEventsSelectedContentController',
                controllerAs: 'eventContentCtrl',
            })
            .state('admin-area.events.event.terms', {
                url: '/terms',
                templateUrl: 'modules/admin-area/views/admin-area.events.selected.terms.client.view.html',
                controller: 'AdminAreaEventsSelectedTermsController',
                controllerAs: 'eventTermsCtrl',
            })

            .state('admin-area.campaigns', {
                url: '/campaigns',
                templateUrl: 'modules/admin-area/views/admin-area.campaigns.client.view.html',
                controller: 'AdminAreaCampaignsController',
                data: {
                    security: {
                        secured: true,
                        roles: ['1'], // admin
                    },
                },
            })

            .state('admin-area.invitations', {
                url: '/invitations',
                templateUrl: 'modules/admin-area/views/admin-area.invitations.client.view.html',
                controller: 'AdminAreaInvitationsController',
                data: {
                    security: {
                        secured: true,
                        roles: ['1'], // admin
                    },
                },
            });
    },
]);

'use strict';

// Setting up route
angular.module('devices').config([
    '$stateProvider',
    '$urlRouterProvider',
    function ($stateProvider, $urlRouterProvider) {
        //        $urlRouterProvider
        //            .when('/home', '/home/main')
        //            .when('/home/vault', '/home/vault/0/all')
        //            .when('/home/portfolio', '/home/portfolio/0/all')
        //            .when('/home/channels', '/home/channels/all');

        var belongsTo = 'home';

        // Users state routing
        $stateProvider
            .state('home.devices', {
                url: '/devices',
                templateUrl: 'modules/devices/views/devices.client.view.html',
                controller: 'DevicesController',
                data: {
                    devicesBelongsTo: belongsTo,
                },
                resolve: {
                    devicesAndGroups: [
                        'DevicesDataService',
                        function (DevicesDataService) {
                            return DevicesDataService.getDevicesAndGroups();
                        },
                    ],
                },
            })

            // Single Device
            .state('home.devices.device', {
                url: '/device/:id',
                templateUrl: 'modules/devices/views/devices.selected.client.view.html',
                controller: 'DevicesSelectedController',
                data: {
                    deviceOrGroup: 'device',
                },
            })

            .state('home.devices.device.content', {
                url: '/content',
                templateUrl: 'modules/devices/views/devices.selected.content.client.view.html',
                controller: 'DevicesSelectedContentController',
            })

            .state('home.devices.device.settings', {
                url: '/settings',
                templateUrl: 'modules/devices/views/devices.selected.settings.client.view.html',
                controller: 'DevicesSelectedSettingsController',
            })

            .state('home.devices.device.download-management', {
                url: '/download-management',
                templateUrl: 'modules/devices/views/devices.selected.download-management.client.view.html',
                controller: 'DevicesSelectedContentController',
            })

            // Group
            .state('home.devices.group', {
                url: '/group/:id',
                templateUrl: 'modules/devices/views/devices.selected.client.view.html',
                controller: 'DevicesSelectedController',
                data: {
                    deviceOrGroup: 'group',
                },
            })

            .state('home.devices.group.content', {
                url: '/content',
                templateUrl: 'modules/devices/views/devices.selected.content.client.view.html',
                controller: 'DevicesSelectedContentController',
            })

            .state('home.devices.group.settings', {
                url: '/settings',
                templateUrl: 'modules/devices/views/devices.selected.settings.client.view.html',
                controller: 'DevicesSelectedSettingsController',
            })

            // Display Array
            .state('home.devices.group.display-array', {
                url: '/display-array',
                templateUrl: 'modules/devices/views/devices.selected.display-array.client.view.html',
                controller: 'DevicesSelectedDisplayArrayController',
            })

            // Add Device - from devices
            .state('home.devices.add-device', {
                url: '/add-device',
                templateUrl: 'modules/devices/views/devices.add-device.client.view.html',
                controller: 'DevicesAddDeviceController',
            })

            // Add Device - standalone
            .state('add-device', {
                url: '/add-device?deviceId&shortSecret&secret&deviceName&deviceType',
                templateUrl: 'modules/devices/views/devices.add-device.client.view.html',
                controller: 'DevicesAddDeviceController',
                resolve: {
                    redirectHereAfterLogin: [
                        '$rootScope',
                        '$stateParams',
                        function ($rootScope, $stateParams) {
                            $rootScope.lastStateBeforeLogin = 'add-device';
                            $rootScope.lastStateParamsBeforeLogin = {
                                deviceId: $stateParams.deviceId,
                                shortSecret: $stateParams.shortSecret,
                                secret: $stateParams.secret,
                                deviceName: $stateParams.deviceName,
                            };
                        },
                    ],
                    continueOnlyIfAuth: [
                        'Security',
                        '$q',
                        '$rootScope',
                        function (Security, $q, $rootScope) {
                            //return Security.status();
                            if ($rootScope.duringDeviceLogin) {
                                return $q.defer().promise;
                            }
                        },
                    ],
                },
                data: {
                    security: {
                        secured: true,
                    },
                },
            })

            .state('pair', {
                url: '/pair?deviceId&shortSecret&secret&deviceName&deviceCode&clickedAddDevice',
                templateUrl: 'modules/devices/views/devices.add-device.client.view.html',
                controller: 'DevicesAddDeviceController',
            })

            // Add Group
            .state('home.devices.add-group', {
                url: '/add-group?fromSelected',
                templateUrl: 'modules/devices/views/devices.add-group.client.view.html',
                controller: 'DevicesAddGroupController',
            })

            // Multiple Devices Are Selected
            .state('home.devices.multiple-devices', {
                url: '/multiple-devices',
                templateUrl: 'modules/devices/views/devices.multiple-devices.client.view.html',
                //                controller: 'DevicesMultipleDevicesController'
            })

            // Groups and Devices Are Selected
            .state('home.devices.multiple-devices-and-groups', {
                url: '/multiple-devices-and-groups',
                templateUrl: 'modules/devices/views/devices.multiple-devices-and-groups.client.view.html',
                //                controller: 'DevicesMultipleDevicesController'
            })

            .state('home.devices.error', {
                url: '/{path:.*}',
                resolve: {
                    notValidLink: [
                        '$rootScope',
                        '$location',
                        '$stateParams',
                        '$log',
                        'Messages',
                        'NIIO_URL',
                        function ($rootScope, $location, $stateParams, $log, Messages, NIIO_URL) {
                            $log.debug(
                                'home.devices.error::',
                                $rootScope.$platformManager.platform.url + '/home/devices' + $stateParams.path
                            );
                            var messageParams = {};
                            messageParams.message =
                                'The URL ' +
                                $rootScope.$platformManager.platform.url +
                                '/home/devices' +
                                $stateParams.path +
                                ' is not valid, redirecting.';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($rootScope, messageParams).then(function (res) {
                                $rootScope.previousState('home');
                            });
                        },
                    ],
                },
            });
    },
]);

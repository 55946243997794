'use strict';

angular.module('devices').controller('DevicesSelectedRemoteControlController', [
    '$scope',
    '$timeout',
    '$interval',
    '$rootScope',
    '$localStorage',
    '$q',
    '$log',
    'DevicesDataService',
    'Messages',
    'PushHandlerService',
    '_',
    function (
        $scope,
        $timeout,
        $interval,
        $rootScope,
        $localStorage,
        $q,
        $log,
        DevicesDataService,
        Messages,
        PushHandlerService,
        _
    ) {
        $scope.toggles = {};
        $scope.toggles.infoIsClosed = true;
        $scope.toggles.spinRefresh = false;
        $scope.toggles.collapsables = {
            map: {},
            isExpanded: function (a, b, c) {
                var k = this._makeKey(a, b, c);
                return this.map[k] === undefined || !this.map[k];
            },
            setDefault: function (v, a, b, c) {
                var k = this._makeKey(a, b, c);
                if (this.map[k] === undefined) {
                    this.map[k] = v;
                }
            },
            collapse: function (a, b, c) {
                var k = this._makeKey(a, b, c);
                this.map[k] = true;
            },
            expand: function (a, b, c) {
                var k = this._makeKey(a, b, c);
                this.map[k] = false;
            },
            toggle: function (a, b, c) {
                var k = this._makeKey(a, b, c);
                this.map[k] = !this.map[k];
            },
            _makeKey: function (a, b, c) {
                var k = a.id;
                if (b !== undefined && b.id !== undefined) {
                    k += '_';
                    k += b.id;

                    if (c !== undefined && c.id !== undefined) {
                        k += '_';
                        k += c.id;
                    }
                }
                return k;
            },
        };

        function getDevicePlaylist(deviceId) {
            DevicesDataService.getDevicePlaylist(deviceId).then(
                function (res) {
                    $scope.playlist = res.data.channels;
                },
                function (error) {
                    alert('error and stuff...');
                }
            );
        }

        $scope.init = function () {
            $log.debug('DevicesSelectedRemoteControlController::init called with params', $scope.params);

            if ($scope.params.device) {
                $scope.device = {};
                angular.extend($scope.device, $scope.params.device);
                initPushNotifications();
                $scope.refreshStatus();
            } else {
                $scope.group = {};
                angular.extend($scope.group, $scope.params.group);
            }

            if (!$localStorage.pushy.notificationIsGranted) {
                $scope.autoRefresh.enable(false);
            }
        };

        function initPushNotifications() {
            DevicesDataService.initPushNotifications($scope.device.id, $scope.deviceOrGroup, $scope.refreshStatus).then(
                function (res) {
                    $log.debug(
                        'DevicesSelectedRemoteControlController::initPushNotifications getPushNotifications',
                        res
                    );
                },
                function (error) {
                    $log.debug(
                        'DevicesSelectedRemoteControlController::initPushNotifications getPushNotifications',
                        error
                    );
                    if ($localStorage.pushy.notificationIsGranted) {
                        var messageParams = {};
                        messageParams.message =
                            'The device is not yet ready to remote control. Please make sure the player is turned on and try again shortly.\nIf the error persists please contact support.';
                        messageParams.title = 'Unexpected Error Occurred';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($scope, messageParams).finally(function (res) {
                            Messages.ok();
                        });
                    }
                }
            );
        }

        function applyCommandImmediatelyAfterPressed(command) {
            if (command === 'Resume' || command === 'Pause') {
                $scope.device.isPaused = !$scope.device.isPaused;
            }
        }

        //Remote Control
        $scope.sendCommandToPlayer = function (command) {
            var ids = [];
            if ($scope.device) {
                ids = [$scope.device.id];
            } else {
                if ($scope.group.devices && $scope.group.devices.length > 0) {
                    ids = _.pluck($scope.group.devices, 'id');
                }
            }
            if (ids.length > 0) {
                applyCommandImmediatelyAfterPressed(command);
                return DevicesDataService.sendCommandToPlayer(command, ids);
            } else {
                var deferred = $q.defer();
                deferred.reject('internal error (43)');
                return deferred.promise;
            }
        };

        $scope.askBeforeCommand = function (command) {
            var messageParams = {};
            messageParams.message = 'This action will perform a ' + command + '. Are you sure you want to continue?';
            messageParams.title = 'Performing ' + command;
            messageParams.size = 'lg';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Yes';
            Messages.openMessage($scope, messageParams).then(function (res) {
                if (res === 'ok') {
                    $scope.sendCommandToPlayer(command);
                }
            });
        };

        $scope.refreshing = false;
        $scope.loadedDeviceCurrentStatusFirstTime = true;
        $scope.currentStatus = undefined;
        $scope.notConnectedStage = undefined;
        $scope.lastAccessed = undefined;
        $scope.ETAG = undefined;
        $scope.livePlaylist = [];

        function broadcastChangeActiveCurrents(oldActive, newActive) {
            // Broadcast event for remote to update current artwork (on / off)
            // var updateEventNewCurrent = newActive.collectionId > -1 ?
            //     'current_active_updated_AR' + newActive.artworkId + '_CO' + newActive.collectionId + '_CH' + newActive.channelId
            //     : 'current_active_updated_AR' + newActive.artworkId + '_CH' + newActive.channelId;
            // $rootScope.$broadcast(updateEventNewCurrent, newActive);
            // if (oldActive.artworkId) {
            //     // To update the old active artwork
            //     var updateEventOldCurrent = oldActive.collectionId > -1 ?
            //         'current_active_updated_AR' + oldActive.artworkId + '_CO' + oldActive.collectionId + '_CH' + oldActive.channelId
            //         : 'current_active_updated_AR' + oldActive.artworkId + '_CH' + oldActive.channelId;
            //     $rootScope.$broadcast(updateEventOldCurrent, newActive);
            // }
            var updateEventCurrent = 'current_active_updated';
            $rootScope.$broadcast(updateEventCurrent, newActive);
        }

        function getUpdatedStatus() {
            if (!$scope.device) {
                var deferred = $q.defer();
                deferred.reject('No device selected');
                return deferred.promise;
            }

            return DevicesDataService.getUpdatedStatus($scope.device.id).then(
                function (newStatus) {
                    $log.debug('DevicesSelectedRemoteControlController::getUpdatedStatus newStatus', newStatus);

                    $scope.loadedDeviceCurrentStatusFirstTime = newStatus.loadedDeviceCurrentStatusFirstTime;
                    if (newStatus.currentStatus && $scope.currentStatus) {
                        // Broadcast event for remote to update current artwork (on / off)
                        if (!_.isEqual(newStatus.currentStatus.currentActive, $scope.currentStatus.currentActive)) {
                            var newActive = newStatus.currentStatus.currentActive;
                            var oldActive = $scope.currentStatus.currentActive;
                            broadcastChangeActiveCurrents(oldActive, newActive);
                        }
                    }

                    $scope.currentStatus = newStatus.currentStatus;
                    $scope.notConnectedStage = newStatus.notConnectedStage;
                    $scope.lastAccessed = newStatus.lastAccessed;
                    $scope.ETAG = newStatus.ETAG;

                    // Sync playlist only if the result has playlist.
                    // In some cases the playlist is not available.
                    if (newStatus.currentStatus.Playlist) {
                        syncDevicePlayListStatus(newStatus);
                    }

                    $scope.toggles.spinRefresh = false;
                    $scope.refreshing = false;
                },
                function (error) {
                    //We should not come here since getUpdatedStatus() always returns status.
                    $log.debug('DevicesSelectedRemoteControlController::getUpdatedStatus Failed', error);
                    $scope.toggles.spinRefresh = false;
                    $scope.refreshing = false;
                }
            );
        }

        function syncDevicePlayListStatus(newStatus) {
            if (!newStatus.currentStatus || newStatus.currentStatus.Playlist === undefined) {
                $scope.livePlaylist = [];
                return;
            }

            //We will update elements'flags  in playlist.
            $scope.livePlaylist = newStatus.currentStatus.Playlist;

            //By default we collapse "large channels" - (ignoee channel-collections)
            for (var c = 0; c < $scope.livePlaylist.length; c++) {
                var ch = $scope.livePlaylist[c];
                if (ch.artworks.length >= 20) {
                    $scope.toggles.collapsables.setDefault(true, ch);
                }
            }
        }

        $scope.expandAll = function () {
            togglesCollapsables(true);
        };
        $scope.collapseAll = function () {
            togglesCollapsables(false);
        };

        function togglesCollapsables(expandAll) {
            for (var c = 0; c < $scope.livePlaylist.length; c++) {
                var ch = $scope.livePlaylist[c];
                if (expandAll) {
                    if (ch.artworks !== undefined && ch.artworks.length < 20) {
                        $scope.toggles.collapsables.expand(ch);
                    }
                } else {
                    $scope.toggles.collapsables.collapse(ch);
                }

                if (ch.artworks !== undefined) {
                    for (var aw = 0; aw < ch.artworks.length; aw++) {
                        if (!ch.artworks[aw].is_artwork) {
                            if (expandAll) {
                                if (ch.artworks[aw].artworks !== undefined && ch.artworks[aw].artworks.length < 20) {
                                    $scope.toggles.collapsables.expand(ch, ch.artworks[aw]);
                                }
                            } else {
                                $scope.toggles.collapsables.collapse(ch, ch.artworks[aw]);
                            }
                        }
                    }
                }
            }
        }

        $scope.toggleChannelCheck = function (channel) {
            var command = (channel.checked ? 'DisableCH%20' : 'EnableCH%20') + channel.id;
            channel.checked = !channel.checked;

            $scope.sendCommandToPlayer(command).then(
                function (res) {
                    if (!$localStorage.pushy.pushyIsSupported || !$localStorage.pushy.notificationIsGranted) {
                        $scope.refreshStatus();
                    }
                },
                function (error) {
                    channel.checked = !channel.checked;
                }
            );
        };

        $scope.toggleCollectionCheck = function (collection, channel) {
            var command =
                (collection.checked ? 'DisableCollection%20' : 'EnableCollection%20') +
                collection.id +
                ',' +
                channel.id;
            collection.checked = !collection.checked;

            $scope.sendCommandToPlayer(command).then(
                function (res) {
                    if (!$localStorage.pushy.pushyIsSupported || !$localStorage.pushy.notificationIsGranted) {
                        $scope.refreshStatus();
                    }
                },
                function (error) {
                    // Reverse all
                    collection.checked = !collection.checked;
                    //TODO: delete makeCollectionToggle(collection, channel);
                }
            );
        };

        $scope.toggleArtworkCheck = function (artwork, channel) {
            var command = (artwork.checked ? 'DisableAW%20' : 'EnableAW%20') + artwork.id + ',' + channel.id;
            artwork.checked = !artwork.checked;

            $scope.sendCommandToPlayer(command).then(
                function (res) {
                    if (!$localStorage.pushy.pushyIsSupported || !$localStorage.pushy.notificationIsGranted) {
                        $scope.refreshStatus();
                    }
                },
                function (error) {
                    artwork.checked = !channel.checked;
                    //TODO: delete syncChannelUICheckedOrNot(channel);
                }
            );
        };

        $scope.selectArtwork = function (artwork, channel) {
            var command = 'JumpToAW%20' + artwork.id + ',' + channel.id;
            $rootScope.$broadcast('artwork_remove_active');
            artwork.isActive = true;

            $scope.sendCommandToPlayer(command).then(
                function (res) {
                    if (!$localStorage.pushy.pushyIsSupported || !$localStorage.pushy.notificationIsGranted) {
                        $scope.refreshStatus();
                    }
                },
                function (error) {
                    artwork.checked = !channel.checked;
                    //TODO: delete syncChannelUICheckedOrNot(channel);
                }
            );
        };

        $scope.isArtworkActive = function (channel, collection, artwork) {
            if (
                $scope.currentStatus === undefined ||
                $scope.currentStatus.currentActive === undefined ||
                $scope.currentStatus.currentActive.artworkId === undefined
            ) {
                return false;
            }
            if (channel === undefined || $scope.currentStatus.currentActive.channelId !== channel.id) {
                return false;
            }
            if (artwork === undefined || $scope.currentStatus.currentActive.artworkId !== artwork.id) {
                return false;
            }

            if (collection !== undefined && $scope.currentStatus.currentActive.collectionId !== collection.id) {
                return false;
            }

            return true;
        };

        $scope.refreshStatus = function (ts) {
            if ($scope.refreshing) {
                return;
            }
            $scope.refreshing = true;
            _refreshStatus(ts);
        };

        function _refreshStatus(ts) {
            var command = 'UpdateStatus';
            $scope.sendCommandToPlayer(command);
            if (!$localStorage.pushy.pushyIsSupported || !$localStorage.pushy.notificationIsGranted) {
                $scope.toggles.spinRefresh = true;
                ts = ts || 2000;
                $timeout(getUpdatedStatus, ts);
            }
        }

        $scope.$on('pushy_update_status', function (event, data) {
            $log.debug('DevicesSelectedController::pushy_update_status', data);
            if (data && $scope.device && $scope.device.id === data.deviceId) {
                getUpdatedStatus();
            }
        });

        $scope.$on('pushy_update_status_real_time', function (event, data) {
            $log.debug('DevicesSelectedController::pushy_update_status_real_time', data);
            if (data && $scope.device && $scope.device.id === data.deviceId) {
                var updatedStatus = DevicesDataService.processShortUpdatedStatus(
                    data.lastAccessed,
                    data.CurChannel,
                    data.CurCollection,
                    data.CurItem,
                    data.ETAG
                );
                if (updatedStatus) {
                    $scope.loadedDeviceCurrentStatusFirstTime = updatedStatus.loadedDeviceCurrentStatusFirstTime;
                    $scope.notConnectedStage = updatedStatus.notConnectedStage;
                    $scope.lastAccessed = updatedStatus.lastAccessed;
                    if ($scope.currentStatus && updatedStatus) {
                        // Broadcast event for remote to update current artwork (on / off)
                        if (!_.isEqual(updatedStatus.currentActive, $scope.currentStatus.currentActive)) {
                            var newActive = updatedStatus.currentActive;
                            var oldActive = $scope.currentStatus.currentActive;
                            broadcastChangeActiveCurrents(oldActive, newActive);
                        }

                        $scope.currentStatus.currentActive = updatedStatus.currentActive;
                    }
                }

                // ETAG is representation of the playlist state.
                // If ETAG changed, it means that something in the playlist has changed
                // and we need to get the full content
                if (!$scope.currentStatus || $scope.ETAG !== data.ETAG) {
                    getUpdatedStatus();
                }
            }
        });

        $scope.$on('pushy_remote_verified', function (event, data) {
            initPushNotifications();
            $log.debug('DevicesSelectedController::pushy_verify_remote', data);
        });

        $scope.$on('$destroy', function () {
            PushHandlerService.cancelRemoteKeepaliveInterval();
        });

        $scope.autoRefresh = {
            enabled: true,
            interval: 30 * 1000,
            enable: function (b) {
                b = b || !this.enabled;
                this.enabled = b;
                if (!b) {
                    this.cancelInterval();
                } else {
                    this.startInterval();
                }
            },
            /*
                isRunning: false,
                bgRefresh: function() {
                    var self = $scope.autoRefresh;
                    if (self.isRunning) {
                        return;
                    }
                    if (!self.enabled) {
                        self.isRunning = false;
                        return;
                    }
                    self.isRunning = true;
                    $timeout(self.startInterval, self.interval);
                },*/
            refreshInterval: undefined,
            cancelInterval: function () {
                var self = $scope.autoRefresh;
                if (angular.isDefined(self.refreshInterval)) {
                    var result = $interval.cancel(self.refreshInterval);
                    self.refreshInterval = undefined;
                }
            },
            startInterval: function () {
                var self = $scope.autoRefresh;
                if (angular.isDefined(self.refreshInterval)) {
                    return;
                }

                self.refreshInterval = $interval(function () {
                    if (!self.enabled) {
                        self.isRunning = false;
                        self.cancelInterval();
                        return;
                    }
                    _refreshStatus();
                }, self.interval);
            },
        };
    },
]);

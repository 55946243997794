'use strict';

angular.module('devices').controller('DevicesSelectedAATVContentController', [
    '$scope',
    '$log',
    '$q',
    '$rootScope',
    '$filter',
    '$state',
    'Messages',
    'DevicesDataService',
    'PushHandlerService',
    'User',
    '_',
    'PLAYER_TYPES',
    function (
        $scope,
        $log,
        $q,
        $rootScope,
        $filter,
        $state,
        Messages,
        DevicesDataService,
        PushHandlerService,
        User,
        _,
        PLAYER_TYPES
    ) {
        let self = this;
        this.submitWasClicked = false;
        this.deviceSearchParams = {
            onlyAAFTV: 'true',
        };
        this.init = function () {
            self.submitWasClicked = false;
            self.isDragging = false;
            // Add a property to track if dragging is over a specific section
            self.dragOverSection = null;
            self.fromDevice = null;
            self.toDevices = [];
            self.queueChanged();
        };

        // Function to update when drag is over a section
        this.setDragOverSection = function (index) {
            self.dragOverSection = index;
        };

        // Function to clear the drag over section flag
        this.clearDragOverSection = function () {
            self.dragOverSection = null;
        };

        this.undo = () => {
            if (self.currentIndex < self.queueStack.length - 1) {
                self.currentIndex++; // Move forward in the stack to an older state.
                self.latestQueue = angular.copy(self.queueStack[self.currentIndex]);
                self.markQueue(self.latestQueue);
            }
            self.calculateCanRedo();
        };

        this.redo = () => {
            if (self.currentIndex > 0) {
                self.currentIndex--; // Move back to a newer state.
                self.latestQueue = angular.copy(self.queueStack[self.currentIndex]);
                self.markQueue(self.latestQueue);
            }
            self.calculateCanRedo();
        };

        this.returnToOriginal = () => {
            self.latestQueue = angular.copy(self.originalQueue);
            self.markQueue(self.latestQueue);
            self.currentIndex = 0; // Initialize currentIndex
            self.calculateCanRedo();
        };

        this.calculateCanRedo = () => {
            self.canUndo = self.currentIndex < self.queueStack.length - 1;
            self.canRedo = self.currentIndex > 0;
            self.isOriginal = _.isEqual(
                self.cleanQueue(angular.copy(self.latestQueue)),
                self.cleanQueue(angular.copy(self.originalQueue))
            );
            $state.params.preventRedirect = !self.isOriginal;
        };

        this.cleanQueue = (queue) => {
            return queue.map((item) => {
                if (item.section === 'channel') {
                    delete item.channelData;
                    delete item.currentlyPlaying;
                    delete item.showActions;
                    delete item.scheduled;
                    delete item.scheduledActivated;
                    delete item.isOriginal;
                    delete item.inQueue;
                    delete item.$$hashKey;
                    // remove artworkData for each artwork in item.artworks if it exists
                    if (item.artworks) {
                        item.artworks = item.artworks.map((artwork) => {
                            delete artwork.artworkData;
                            delete artwork.currentlyPlaying;
                            delete artwork.downloading;
                            delete artwork.downloaded;
                            delete artwork.downloadSize;
                            delete artwork.retryingDownload;
                            delete artwork.failedDownload;
                            delete artwork.deleting;
                            delete artwork.showActions;
                            delete artwork.scheduled;
                            delete artwork.scheduledActivated;
                            delete artwork.inQueue;
                            delete artwork.$$hashKey;
                            return artwork;
                        });
                    }
                } else if (item.section === 'custom-queue') {
                    delete item.channelData;
                    delete item.currentlyPlaying;
                    delete item.showActions;
                    delete item.scheduled;
                    delete item.scheduledActivated;
                    delete item.isOriginal;
                    delete item.inQueue;
                    delete item.$$hashKey;
                    if (item.artworks) {
                        item.artworks = item.artworks.map((artwork) => {
                            delete artwork.artworkData;
                            delete artwork.currentlyPlaying;
                            delete artwork.downloading;
                            delete artwork.downloaded;
                            delete artwork.retryingDownload;
                            delete artwork.failedDownload;
                            delete artwork.downloadSize;
                            delete artwork.deleting;
                            delete artwork.showActions;
                            delete artwork.scheduled;
                            delete artwork.scheduledActivated;
                            delete artwork.inQueue;
                            delete artwork.$$hashKey;
                            return artwork;
                        });
                    }
                }
                return item;
            });
        };

        this.latestQueueChanged = () => {
            // Prepend the current state to the start of the stack.
            self.queueStack.unshift(angular.copy(self.latestQueue));

            // Reset latestQueueCopy to the current latest state after the change.
            self.latestQueueCopy = angular.copy(self.latestQueue);

            // After adding a new state, currentIndex should still point to the latest state, which is now at index 0.
            self.currentIndex = 0;

            // Recalculate the flags for undo and redo availability.
            self.calculateCanRedo();

            self.totalArtworks = countTotalArtworks(self.latestQueue);

            markChannelsAsBasedOn(self.latestQueue);
            markQueueDownloads(self.latestQueue, $scope.selected?.downloads);
        };

        this.queueChanged = () => {
            // if the queue includes a custom-queue section, add "artworks" property to it
            if (DevicesDataService.queue) {
                let customQueue = _.find(DevicesDataService.queue, (section) => {
                    return section.section === 'custom-queue';
                });

                if (customQueue && !customQueue.artworks) {
                    customQueue.artworks = [];
                }
            }
            self.originalQueue = angular.copy(DevicesDataService.queue) ?? [];
            self.latestQueue = angular.copy(DevicesDataService.queue) ?? [];
            self.markQueue(self.latestQueue);
            self.totalArtworks = countTotalArtworks(self.latestQueue);
            self.latestQueueCopy = angular.copy(self.latestQueue) ?? [];
            self.queueStack = self.originalQueue ? [self.originalQueue] : [];
            self.currentIndex = 0; // Initialize currentIndex
            self.calculateCanRedo();
        };

        this.markQueue = (queue) => {
            markChannelsAsScheduled(queue, $scope.settings.schedule);
            if ($scope.selected?.current_status?.currentArtwork || $scope.selected?.current_status?.currentChannel) {
                markChannelsAsCurrentlyPlaying(
                    queue,
                    $scope.selected?.current_status?.currentArtwork,
                    $scope.selected?.current_status?.currentChannel
                );
            }
            markChannelsAsBasedOn(queue);
            markQueueDownloads(queue, $scope.selected?.downloads);
        };

        function countTotalArtworks(queue) {
            let totalArtworks = 0;
            queue.forEach((section) => {
                if (section.artworks) {
                    totalArtworks += section.artworks.length;
                }
            });
            return totalArtworks;
        }

        function markChannelsAsScheduled(queue, schedule) {
            if (schedule) {
                let scheduledChannels = schedule
                    .map((scheduleItem) => {
                        if (scheduleItem.enabled === 'true' && scheduleItem?.channels?.length > 0) {
                            return scheduleItem.channels[0];
                        }
                    })
                    .filter((channel) => channel !== undefined);

                // Reset scheduled flag for all sections
                queue.forEach((section) => {
                    if (section.section === 'channel') {
                        delete section.scheduled;
                        // Mark all artworks in the channel as scheduled
                        section?.artworks.forEach((artwork) => {
                            delete artwork.scheduled;
                        });
                    }
                });

                // Mark channels as scheduled
                if (scheduledChannels?.length > 0) {
                    queue.forEach((section) => {
                        if (section.section === 'channel' && scheduledChannels.includes(section.channelID)) {
                            section.scheduled = true;
                            // Mark all artworks in the channel as scheduled
                            section.artworks.forEach((artwork) => {
                                artwork.scheduled = true;
                            });
                        }
                    });
                }

                // Mark schannels as scheduledActivated
                if ($scope.selected?.current_status?.currentSchedule?.channels) {
                    queue.forEach((section) => {
                        if (
                            section.section === 'channel' &&
                            $scope.selected.current_status.currentSchedule.channels.includes(section.channelID)
                        ) {
                            section.scheduledActivated = true;
                            // Mark all artworks in the channel as scheduled
                            section.artworks.forEach((artwork) => {
                                artwork.scheduledActivated = true;
                            });
                        }
                    });
                }
            }
        }

        function markChannelsAsBasedOn(queue) {
            queue.forEach((section) => {
                if (section.section === 'channel') {
                    section.isOriginal = true;
                    // Check if channel retained original artworks

                    // Extract the unique IDs from both arrays
                    const idsArr1 = section.artworks.map((item) => item.artworkID);
                    const idsArr2 = section.channelData.artworks.map((item) => item.id);

                    // Check if the arrays are of the same length
                    if (idsArr1.length !== idsArr2.length) {
                        section.isOriginal = false;
                    }

                    // Check for missing items or mismatches
                    for (let i = 0; i < idsArr1.length; i++) {
                        if (idsArr1[i] !== idsArr2[i]) {
                            section.isOriginal = false;
                        }
                    }
                }
            });
        }

        function markQueueDownloads(queue, downloads) {
            if (downloads !== undefined && queue !== undefined) {
                queue.forEach((section) => {
                    section.artworks.forEach((artwork) => {
                        delete artwork.downloaded;
                        delete artwork.downloading;
                        delete artwork.downloadSize;
                        delete artwork.failedDownload;
                    });
                });

                if (downloads?.downloads?.length > 0) {
                    downloads.downloads.forEach((download) => {
                        delete download.inQueue;
                        delete download.failedDownload;

                        if (download.status === 'DOWNLOADED') {
                            if (download.fileSize === undefined) {
                                download.failedDownload = true;
                            } else {
                                download.downloaded = true;
                                download.downloading = false;
                            }
                        } else if (download.status === 'DOWNLOADING') {
                            download.downloading = true;
                            delete download.downloaded;
                        } else if (download.status === 'DOWNLOAD_FAILED') {
                            download.failedDownload = true;
                            delete download.downloading;
                            delete download.downloaded;
                        }

                        queue.forEach((section) => {
                            section.artworks.forEach((artworkInSection) => {
                                if (download.artworkID === artworkInSection.artworkID) {
                                    download.inQueue = true;

                                    if (download.status === 'DOWNLOADED') {
                                        artworkInSection.downloaded = true;
                                        artworkInSection.downloading = false;
                                        if (download.fileSize === undefined) {
                                            artworkInSection.failedDownload = true;
                                            delete artworkInSection.downloadSize;
                                        } else {
                                            artworkInSection.downloadSize = download.fileSize;
                                        }
                                    } else if (download.status === 'DOWNLOADING') {
                                        artworkInSection.downloading = true;
                                        delete artworkInSection.downloaded;
                                    } else if (download.status === 'DOWNLOAD_FAILED') {
                                        artworkInSection.failedDownload = true;
                                        delete artworkInSection.downloading;
                                        delete artworkInSection.downloaded;
                                    }
                                }
                            });
                        });
                    });
                }
            }
        }

        function markChannelsAsCurrentlyPlaying(queue, currentArtwork, currentChannel) {
            self.currentlyPlayingScheduledChannelID = null;
            if (queue !== undefined) {
                queue.forEach((section) => {
                    if (
                        (!currentChannel && section.section !== 'channel') ||
                        (currentChannel && section.section === 'channel' && section.channelID === currentChannel)
                    ) {
                        section.currentlyPlaying = true;
                        if (section.scheduledActivated) {
                            self.currentlyPlayingScheduledChannelID = section.channelID;
                        }
                    }

                    if (section.artworks) {
                        section.artworks.forEach((artwork) => {
                            if (section.currentlyPlaying)
                                if (currentArtwork && artwork.artworkID === currentArtwork) {
                                    artwork.currentlyPlaying = true;
                                }
                        });
                    }
                });
            }
        }

        this.saveQueue = () => {
            let deferred = $q.defer();
            const userId = $scope.adminUserId ?? User.getId();
            self.submitWasClicked = true;
            let cleanQueue = self.cleanQueue(angular.copy(self.latestQueue));

            DevicesDataService.updateQueue(
                $scope.selectedId,
                userId,
                cleanQueue,
                PushHandlerService.getRemoteId(),
                true
            )
                .then(
                    (res) => {
                        // self.originalQueue = angular.copy(vm.latestQueue);
                        DevicesDataService.queue = angular.copy(res);
                        self.queueChanged();
                        var messageParams = {};
                        messageParams.message = 'Queue was updated successfully';
                        if (!$scope.selected?.is_online) {
                            messageParams.message +=
                                '\nPlease note: The device is offline. Changes will apply once it comes back online.';
                            messageParams.disableAutoDismiss = true;
                        }
                        messageParams.title = 'Queue Update';
                        Messages.openMessage($scope, messageParams);
                        deferred.resolve();
                    },
                    function (error) {
                        var messageParams = {};
                        messageParams.message = 'Failed to update queue. Please refresh the page and try again.';
                        messageParams.title = 'Queue Update Failed';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($scope, messageParams);
                        deferred.reject();
                    }
                )
                .finally(() => {
                    self.submitWasClicked = false;
                });

            return deferred.promise;
        };

        // Send commands to player
        this.sendCommandToPlayer = (command, data) => {
            self.isOriginal = _.isEqual(
                self.cleanQueue(angular.copy(self.originalQueue)),
                self.cleanQueue(angular.copy(self.latestQueue))
            );

            if (!self.isOriginal) {
                let messageParams = {};
                messageParams.message =
                    'Actions are allowed only on a saved queue.\nPlease save the queue first and try again.';
                messageParams.title = 'Unsaved Queue';
                messageParams.disableAutoDismiss = true;
                Messages.openMessage($scope, messageParams);
                let deferred = $q.defer();
                deferred.reject('Unsaved queue');
                return deferred.promise;
            } else if ($scope.selected?.is_online === false) {
                let messageParams = {};
                messageParams.message = 'Please note: The player is offline. The changes will not be applied.';
                messageParams.title = 'Offline Player';
                messageParams.disableAutoDismiss = true;
                Messages.openMessage($scope, messageParams);
                let deferred = $q.defer();
                deferred.reject('Offline Player');
                return deferred.promise;
            } else {
                let ids = [];
                if ($scope.deviceOrGroup === 'device') {
                    ids = [$scope.selectedId];
                }
                // else {
                //     if ($scope.group?.devices && $scope.group?.devices.length > 0) {
                //         ids = _.pluck($scope.group.devices, 'id');
                //     }
                // }
                if (ids.length > 0) {
                    const userId = $scope.adminUserId ?? User.getId();
                    return DevicesDataService.sendMessageToNotificationsChannel(command, ids, data, userId);
                } else {
                    var deferred = $q.defer();
                    deferred.reject('internal error (43)');
                    return deferred.promise;
                }
            }
        };

        this.downloadArtwork = (artwork) => {
            let data = {
                id: artwork.artworkID,
            };
            self.sendCommandToPlayer('PlayerDownloadArtwork', data).then(() => {
                artwork.downloading = true;
                let messageParams = {};
                messageParams.message = 'Downloading Artwork ' + artwork.artworkData.title;
                Messages.openMessage($scope, messageParams);
            });
        };

        this.retryDownloadArtwork = (artwork) => {
            let data = {
                id: artwork.artworkID,
            };
            self.sendCommandToPlayer('PlayerRetryDownloadArtwork', data).then(() => {
                artwork.downloading = true;
                let messageParams = {};
                messageParams.message = 'Retrying Download Artwork ' + artwork.artworkData.title;
                Messages.openMessage($scope, messageParams);
            });
        };

        this.deleteDownloadedArtwork = (artwork, hideNotification) => {
            let data = {
                id: artwork.artworkID,
            };
            self.sendCommandToPlayer('PlayerDeleteDownloadedArtwork', data).then(() => {
                delete artwork.downloading;
                artwork.deleting = true;
                if (!hideNotification) {
                    let messageParams = {};
                    messageParams.message = 'Deleting Downloaded Artwork ' + artwork.artworkData.title;
                    Messages.openMessage($scope, messageParams);
                }
            });
        };

        this.deleteAllDownloads = () => {
            let messageParams = {};
            messageParams.title = 'Delete All Downloads';
            messageParams.message =
                'You are about to delete all downloaded artworks. Are you sure you want to proceed?';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Delete';
            messageParams.okClass = 'danger';
            Messages.openMessage($rootScope, messageParams).then(function (res) {
                if (res === 'ok') {
                    $scope.selected.downloads.downloads.forEach((download) => {
                        self.deleteDownloadedArtwork({ artworkID: download.artworkID }, true);
                    });
                    let messageParams = {};
                    messageParams.message = 'All artworks were deleted successfully';
                    Messages.openMessage($scope, messageParams);
                }
            });
        };

        this.retryDownloadArtwork = (artwork) => {
            let data = {
                id: artwork.artworkID,
            };
            self.sendCommandToPlayer('PlayerRetryDownloadArtwork', data).then(() => {
                delete artwork.downloading;
                artwork.retryingDownload = true;
                let messageParams = {};
                messageParams.message = 'Retrying Downloading Artwork ' + artwork.artworkData.title;
                Messages.openMessage($scope, messageParams);
            });
        };

        this.sortableSectionOptions = {
            orderChanged: (event) => {
                const originalPosition = event.source.index;
                const newPosition = event.dest.index;
                console.log('Section order changed');
                self.latestQueueChanged();
            },
            itemMoved: function (event) {
                console.log('Section moved');
                // Implement the logic to handle section movement
                self.latestQueueChanged();
            },
            accept: (sourceItemHandleScope, destSortableScope) => {
                // Prevent sections from being dragged into another section
                // Assuming sections and artworks can be distinguished by a unique property
                const isDraggingSection = sourceItemHandleScope.itemScope.modelValue?.section;
                const isScheduled = sourceItemHandleScope.itemScope.modelValue?.scheduled;
                return !!isDraggingSection; // Only allow if dragging a section
            },
            containment: '.queue-container',
        };

        this.sortableArtworkOptions = {
            itemMoved: (event) => {
                const movedArtwork = event.source.itemScope.modelValue;
                const oldSectionIndex = event.source.sortableScope.$parent.$index;
                const newSectionIndex = event.dest.sortableScope.$parent.$index;

                console.log(`Artwork moved from section ${oldSectionIndex} to ${newSectionIndex}`);
                self.latestQueueChanged();
            },
            orderChanged: (event) => {
                const originalPosition = event.source.index;
                const newPosition = event.dest.index;

                console.log(
                    `Artwork moved from position ${originalPosition} to ${newPosition} within the same section`
                );
                self.latestQueueChanged();
            },
            accept: (sourceItemHandleScope, destSortableScope) => {
                // Prevent sections from being dragged into another section
                // Assuming sections and artworks can be distinguished by a unique property
                const isDraggingSection = sourceItemHandleScope.itemScope.modelValue?.section;
                const isScheduled = sourceItemHandleScope.itemScope.modelValue?.scheduled;
                let fromSameSection = false;
                let sectionIsScheduled = false;
                if (destSortableScope.modelValue.length > 0) {
                    let movingArtworkInsideSameSection = _.find(destSortableScope.modelValue, (artworkInSection) => {
                        return (
                            artworkInSection.originalChannelID ===
                            sourceItemHandleScope.itemScope.modelValue?.originalChannelID
                        );
                    });
                    if (movingArtworkInsideSameSection) {
                        fromSameSection = true;
                    }
                    let movingToScheduledSection = _.find(destSortableScope.modelValue, (artworkInSection) => {
                        return artworkInSection.scheduled;
                    });
                    if (movingToScheduledSection) {
                        sectionIsScheduled = true;
                    }
                }
                return (
                    !isDraggingSection && (fromSameSection || (!fromSameSection && !isScheduled && !sectionIsScheduled))
                ); // Only allow if dragging an artwork
            },
            dragStart: function (e, ui) {
                self.isDragging = true;
            },
            dragEnd: function (e, ui) {
                self.isDragging = false;
            },
            containment: '.queue-container',
        };

        this.addArt = () => {
            const userId = $scope.adminUserId ?? User.getId();
            let content = {};

            DevicesDataService.fetchSubscribedChannels('', userId, '1').then(function (res) {
                // self.originalQueue = angular.copy(vm.latestQueue);
                content.channels = res.data.data.channels;

                self.entityToAdd = null;
                let messageParams = {};
                messageParams.hideHeader = true;
                messageParams.disableAutoDismiss = true;
                messageParams.okText = 'Close';
                messageParams.template =
                    'modules/devices/views/devices.selected.content.aatv.add-content.client.view.html';
                messageParams.size = 'lg';
                // messageParams.templateController = 'DevicesSelectedAATVContentController';
                // messageParams.templateParams = { device: device, group: group };
                messageParams.windowClass = 'footer-set-higher-z-index modal-medium-width';
                messageParams.templateParams = { content: content };

                Messages.openMessage($scope, messageParams);
            });
        };

        this.onAddArt = (entity) => {
            if (self.latestQueue === undefined) {
                // From the add content modal
                $rootScope.$broadcast('entity_added_to_queue', entity);
            } else {
                if (entity?.selected?.type === 'artwork') {
                    // Add artwork to the custom-queue section in latest queue
                    let customQueue = _.find(self.latestQueue, (section) => {
                        return section.section === 'custom-queue';
                    });

                    if (!customQueue) {
                        self.latestQueue.push({
                            section: 'custom-queue',
                            artworks: [{ artworkID: entity.selected.id, artworkData: entity.selected }],
                        });
                    } else {
                        if (!customQueue.artworks) {
                            customQueue.artworks = [];
                        }
                        customQueue.artworks.push({ artworkID: entity.selected.id, artworkData: entity.selected });
                    }
                } else if (entity?.selected?.type === 'artcast' || entity?.selected?.type === 'catalog') {
                    // Add artwork to the channel section in latest queue
                    let artworks = [];
                    if (entity.entity?.artworks?.length > 0) {
                        artworks = entity.entity.artworks.map(function (artwork) {
                            return { artworkID: artwork.id, originalChannelID: entity.selected.id };
                        });
                    }

                    self.latestQueue.push({
                        section: 'channel',
                        channelID: entity.selected.id,
                        artworks: artworks,
                    });
                } else if (entity.hasOwnProperty('artworks')) {
                    // Add artwork to the channel section in latest queue
                    let artworks = [];
                    if (entity.artworks?.length > 0) {
                        artworks = entity.artworks.map(function (artwork) {
                            return { artworkID: artwork.id, originalChannelID: entity.id };
                        });
                    }

                    self.latestQueue.push({
                        section: 'channel',
                        channelID: entity.id,
                        artworks: artworks,
                    });
                }
                let cleanQueue = self.cleanQueue(angular.copy(self.latestQueue));

                DevicesDataService.getQueueData($scope.selectedId, cleanQueue).then((res) => {
                    self.latestQueue = res;
                    self.latestQueueChanged();
                });

                let messageParams = {};
                messageParams.message =
                    $filter('upperFirstLetter')(
                        entity?.selected?.type || (entity.type === 'AC' ? 'artcast' : 'catalog')
                    ) +
                    ' ' +
                    entity.name +
                    ' was added successfully';
                messageParams.title = 'Art Added';
                Messages.openMessage($scope, messageParams);
            }
        };

        this.removeArtwork = (section, artworkToRemove) => {
            let messageParams = {};
            messageParams.title = 'Remove Artwork from Queue';
            messageParams.message =
                'Artwork ' +
                artworkToRemove.artworkData.title +
                ' will be removed from section ' +
                (section.channelData && section.channelData.name ? section.channelData.name : 'Custom-Queue') +
                '. Are you sure you want to remove it?';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Remove';
            messageParams.okClass = 'danger';
            Messages.openMessage($rootScope, messageParams).then(function (res) {
                if (res === 'ok') {
                    const index = section.artworks.findIndex(
                        (artwork) => artwork.artworkID === artworkToRemove.artworkID
                    );
                    const artworkName = section.artworks[index].artworkData.title;
                    if (index > -1) {
                        section.artworks.splice(index, 1);
                        let cleanQueue = self.cleanQueue(angular.copy(self.latestQueue));

                        DevicesDataService.getQueueData($scope.selectedId, cleanQueue).then((res) => {
                            self.latestQueue = res;
                            self.latestQueueChanged();
                        });
                    }

                    let messageParams = {};
                    messageParams.message = 'Artwork ' + artworkName + ' was removed successfully';
                    messageParams.title = 'Artwork Removed';
                    Messages.openMessage($scope, messageParams);
                }
            });
        };

        this.removeSection = (sectionToRemove) => {
            let messageParams = {};
            messageParams.title = 'Remove Section from Queue';
            messageParams.message =
                'Section ' +
                (sectionToRemove.channelData && sectionToRemove.channelData.name
                    ? sectionToRemove.channelData.name
                    : 'Custom-Queue') +
                ' will be removed. Are you sure you want to remove it?';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Remove';
            messageParams.okClass = 'danger';
            Messages.openMessage($rootScope, messageParams).then(function (res) {
                if (res === 'ok') {
                    const index = self.latestQueue.findIndex((section) => {
                        if (sectionToRemove.section === 'custom-queue') {
                            return section.section === 'custom-queue';
                        } else {
                            return section.channelID === sectionToRemove.channelID;
                        }
                    });
                    const sectionName =
                        sectionToRemove.channelData && sectionToRemove.channelData.name
                            ? sectionToRemove.channelData.name
                            : 'Custom-Queue';
                    if (index > -1) {
                        self.latestQueue.splice(index, 1);
                        let cleanQueue = self.cleanQueue(angular.copy(self.latestQueue));
                        DevicesDataService.getQueueData($scope.selectedId, cleanQueue).then((res) => {
                            self.latestQueue = res;
                            self.latestQueueChanged();
                        });
                    }

                    let messageParams = {};
                    messageParams.message = 'Section ' + sectionName + ' was removed successfully';
                    messageParams.title = 'Section Removed';
                    Messages.openMessage($scope, messageParams);
                }
            });
        };

        this.copyQueue = (from, to) => {
            let deferred = $q.defer();
            self.submitWasClicked = true;
            if (!to) {
                to = [$scope.selectedId];
            } else {
                to = _.map(to, 'id');
            }
            if (!from) {
                from = $scope.selectedId;
            } else {
                from = from.id;
            }
            let params = {};

            let messageParams = {};
            messageParams.title = 'Copy Queue';
            messageParams.message = 'You are about to copy queue from device id ' + from + ' to devices: ' + to;
            messageParams.message += '\nAre you sure you want to proceed?';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Copy';
            Messages.openMessage($rootScope, messageParams).then(function (res) {
                if (res === 'ok') {
                    DevicesDataService.copyQueue(from, to, params)
                        .then(
                            function (res) {
                                var messageParams = {};
                                messageParams.message =
                                    'Queue was copied successfully from device ' + from + ' to devices: ' + res;
                                messageParams.title = 'Queue Copied';
                                messageParams.disableAutoDismiss = true;
                                Messages.openMessage($scope, messageParams);
                                deferred.resolve();
                            },
                            function (error) {
                                var messageParams = {};
                                messageParams.message = 'Failed to copy queue: ' + error?.text;
                                messageParams.title = 'Queue Copy Failed';
                                messageParams.disableAutoDismiss = true;
                                Messages.openMessage($scope, messageParams);
                                deferred.reject();
                            }
                        )

                        .finally(() => {
                            self.submitWasClicked = false;
                            self.fromDevice = null;
                            self.toDevices = [];
                        });

                    return deferred.promise;
                }
            });
        };

        this.removeFrom = () => {
            self.fromDevice = null;
        };

        this.removeTo = (id) => {
            self.toDevices = _.reject(self.toDevices, (device) => device.id === id);
        };

        this.onAddDeviceFrom = (entity) => {
            if (entity?.selected?.type === 'device') {
                // Add device to the copy from field
                self.fromDevice = entity.selected;
            }
        };

        this.onAddDeviceTo = (entity) => {
            if (entity?.selected?.type === 'device') {
                // Add device to the copy from field
                self.toDevices.push(entity.selected);
            }
        };

        $scope.$on('content_fetched', function (event) {
            $scope.$emit('selected_chosen', $scope.selectedId, $scope.deviceOrGroup);
            self.init();
        });

        $scope.$on('device_fetched', function (event) {
            self.init();
        });

        $scope.$on('entity_added_to_queue', function (event, entity) {
            if (self.latestQueue !== undefined) {
                self.onAddArt(entity);
            }
        });

        $scope.$on('mark_downloaded_artworks_in_queue', function (event, entity) {
            if (self.latestQueue !== undefined) {
                markQueueDownloads(self.latestQueue, $scope.selected?.downloads);
            }
        });

        if ($scope.contentFetched) {
            self.init();
        }

        $scope.$watch(
            'selected.current_status',
            (newVal, oldVal) => {
                if (!newVal) {
                    return;
                }
                if (newVal !== oldVal) {
                    markChannelsAsCurrentlyPlaying(
                        self.latestQueue,
                        $scope.selected?.current_status?.currentArtwork,
                        $scope.selected?.current_status?.currentChannel
                    );
                }
            },
            true
        );

        // $scope.$watch(
        //     'selected.downloads',
        //     (newVal, oldVal) => {
        //         if (!newVal) {
        //             return;
        //         }
        //         if (newVal !== oldVal) {
        //             markQueueDownloads(self.latestQueue, $scope.selected?.downloads);
        //         }
        //     },
        //     true
        // );

        $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
            if ($state.params.preventRedirect && !$scope.leaveModalOpen) {
                $scope.leaveModalOpen = true;
                $state.params.preventRedirect = false;
            }

            // if ($state.params.preventRedirect && !$scope.leaveModalOpen) {
            //     $scope.leaveModalOpen = true;
            //     event.preventDefault();
            //     let messageParams = {};
            //     messageParams.title = 'Queue Not Saved';
            //     messageParams.message = 'Queue was changed but not saved. Are you sure you want to leave?';
            //     messageParams.disableAutoDismiss = true;
            //     messageParams.cancelText = 'Cancel';
            //     messageParams.okText = 'Leave';
            //     messageParams.okClass = 'danger';
            //     Messages.openMessage($rootScope, messageParams).then(function (res) {
            //         $scope.leaveModalOpen = false;
            //         if (res === 'ok') {
            //             // User chose to leave without saving, continue with the destroy event
            //             $state.params.preventRedirect = false;
            //             $state.go(toState, toParams);
            //         } else {
            //             $state.go(fromState, fromParams);
            //         }
            //     });
            // }
        });
    },
]);
